<template>
  <header class="header">
    <div class="header-content flex">
      <div class="logo flex items-center cursor-pointer">
        <router-link :to="'/'"
          ><img src="../assets/img/logo/logo-pc.svg" alt="logo"
        /></router-link>
      </div>
      <div class="menu flex items-center">
        <ul class="menu-b flex mb-0" v-if="dataMenu">
          <li
            class="item"
            v-for="(item, index) in dataMenu"
            :key="index"
            @click="
              setMenuActiveM(
                index,
                index === 0 || index === 4 || index === 5 ? true : 0
              )
            "
          >
            <router-link :to="item.link">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="action flex items-center">
        <div
          class="action__search flex items-center cursor-pointer"
          @click="activeSearch = !activeSearch"
        >
          <img src="../assets/img/header/search-top.svg" alt="search" />
          <span>{{ trans("frontend.button.search") }}</span>
        </div>
        <translate />
        <div
          class="action__menu-detail flex items-center cursor-pointer"
          @click="activeMenu = !activeMenu"
        >
          <img
            class="menu-icon"
            src="../assets/img/header/menu.svg"
            alt="menu"
          />
          <span>MENU</span>
        </div>
      </div>
    </div>
    <popupMenu
      :activeMenuP="activeMenu"
      :activeSearchP="activeSearch"
      @setPopupSearch="setPopupSearch"
      @setPopupMenu="setPopupMenu"
    />
  </header>
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import popupMenu from "@/components/popup/popupMenu.vue";
import translate from "@/components/translate/translate.vue";
export default {
  components: {
    popupMenu,
    translate,
  },
  data() {
    return {
      activeMenu: false,
      activeSearch: false,
      dataLang: {
        vi: {
          name: "VN",
          link: "../img/icons/vn-flag.svg",
        },
        en: {
          name: "EN",
          link: "../img/icons/en-flag.svg",
        },
      },
    };
  },
  setup() {
    const router = useRoute();
    return {
      meta: computed(() => router.meta),
    };
  },
  // mounted: function () {
  //   this.$nextTick(function () {
  //     $(".menu-toggle .item").click(function (event) {
  //       if (event.target.classList.value.includes("active")) {
  //         $(".menu-toggle .item").removeClass("active");
  //         return;
  //       }
  //       $(".menu-toggle .item").removeClass("active");
  //       event.target.classList.toggle("active");
  //     });
  //   });

  //   // this.getDataMenu();
  // },
  computed: {
    ...mapGetters(["locale", "dataMenu"]),
  },
  methods: {
    setMenuActiveM(index, sub) {
      this.$store.commit("setMenuActive", index);
      this.$store.commit("setSubActive", sub);
    },
    setPopupMenu(data) {
      this.activeMenu = data;
    },
    setPopupSearch(data) {
      this.activeSearch = data;
    },
    activeBook() {
      this.$store.commit("setMenuActive", 7);
      this.$store.commit("setSubActive", 1);
    },
    closeMenu() {
      this.activeMenu = false;
    },
    closeSearch() {
      this.activeSearch = false;
    },
    onListClicked(event) {
      event.target.classList.toggle("menu-active");
    },
  },
};
</script>
