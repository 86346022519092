<template>
  <HeaderHome />
  <slot />
  <Footer />
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";

import Footer from "@/components/Footer.vue";
import HeaderHome from "@/components/HeaderHome.vue";
export default {
  components: { HeaderHome, Footer },
  setup() {
    const route = useRoute();

    return {
      meta: computed(() => route.meta),
    };
  },
};
</script>
